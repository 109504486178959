import React, { useState } from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { color, font, buttons, breakpoint } from '../components/styles'
import video from '../videos/gravity_tray.mp4'
import poster from '../imgs/gravity-tray/video-cover.png'
import bottom from "../imgs/gravity-tray/GravityTray_Bottom.png"
import top from "../imgs/gravity-tray/GravityTray_Top.png"
import side from "../imgs/gravity-tray/GravityTray_Side.png"
import angle from "../imgs/gravity-tray/GravityTray_Hero.png"


const Component = styled.div`
  width: 100%;
  margin: auto;

  .text {
    margin: 0 auto 4em;
    text-align: center;
  }

  .banner-video {
    width: 85%;
    max-width: 1400px;
    margin: 40px auto;
    video {
      max-width: 100%;
      width: 100%;
    }
  }

  .cta {
    width: 100%;
    text-align: center;
    padding: 40px 0;
    &.cta-full {
      background-color: #8cc63e;
      color: ${color.text.white};
      padding: 50px 0;
    }
    &.cta-list {
      .list {
        display: flex;
        flex-flow: row wrap;
        margin: auto;
        justify-content: space-between;
        max-width: 1000px;
        ul {
          display: flex;
          flex-flow: column;
          margin: 0;
          li {
            text-align: left;
            padding: 10px;
            font-size: 1.5em;
            font-weight: 500;
          }
        }
      }
    }
    
    h1 {
      color: #8cc63e;
      font-weight: 700; 
      font-size: 5em;
      margin: 0;
      i {
        font-weight: 400;
        margin: 0 .1em 0 -.2em;
      }
      span {
        font-weight: 400;
      }
    }
    h2 {
      color: #333;
      margin: 0;
      font-size: 1.75em;
    }
    h3 {
      font-weight: 700; 
      font-size: 2em;
      margin: 0;
      i {
        font-weight: 400;
        margin: 0 .1em 0 -.2em;
      }
      span {
        font-weight: 400;
      }
    }
    p {
      max-width: 700px;
      margin: 30px auto 0;
      font-size: 1.2em;
    }
  }

  .image-row {
    width: 85%;
    max-width: 1400px;
    margin: auto;
    display: flex;
    flex-flow: row wrap;
    margin-bottom: 5em;
    img {
      width: 50%;
      padding: 20px;
    }
  }

  .image-column {
    width: 85%;
    max-width: 1400px;
    margin: 3em auto;
    display: flex;
    flex-flow: column;
    img {
      max-width: 90%;
      margin: 5em auto;
    }
  }

  .form {
    width: 100%;
    margin: auto;
    position: relative;
    form {
      max-width: 600px;
      margin: auto;
      display: block;
      width: 100%;
      
      .form_group {
        display: inline-block;
        padding: 15px;
        min-width: 200px;
        &[data-size='100%'] {
          width: 100%;
        }
        &[data-size='50%'] {
          width: 50%;
        }
      }

      label {
        display: none;
      }
      input, textarea {
        font-family: ${font.family.default};
        width: 100%;
        border: none;
        border-bottom: 1px solid #000;
        padding: 12px;
        font-size: 1em;
        transition: border .3s ease-in-out;
        
        &:focus:required:invalid {
          border-color: red;
          box-shadow: none;
          outline: none;
        }
        &:focus:required:valid {
          border-color: ${color.primary};
          box-shadow: none;
          outline: none;
        }
        &:invalid {
          box-shadow: none;
          outline: none;
        }
        &:focus {
          border-color: ${color.primary};
          box-shadow: none;
          outline: none;
        }
      }
      .submit {
        text-align: center;
        margin: 30px auto 0;
      }
    }
    .response {
      color: ${color.text.white};
      text-align: center;
      .message {
        margin: 2em 0 0;
      }
      .status {
        margin: 0 auto;
        width: 2em;
        height: 2em;
        border-radius: 50%;
        position: relative;
      }
      &.success {
        .status {
          animation: none;
          background: ${color.primary};
          &:before, &:after {
            content: '';
            position: absolute;
          }
          &:before {
            border-radius: 0;
            background: transparent;
            transform: rotate(45deg);
            top: 15%;
            left: 30%;
            height: 50%;
            width: 25%;
            border-bottom: 3px solid ${color.white};
            border-right: 3px solid ${color.white};
            z-index: 1;
          }
          &:after {
            background: ${color.primary};
          }
        }
      }
      &.error {
        .status {
          animation: none;
          background: red;
          &:before, &:after {
            position: absolute;
            content: '';
            height: 70%;
            width: 3px;
            background-color: ${color.white};
            border: 0;
            border-radius: 0;
            margin: 0;
            top: 15%;
            left: 44.5%;
          }
          &:before {
            transform: rotate(45deg);
          }
          &:after {
            transform: rotate(-45deg);
          }
        }
      }
    }
  }

  .form {
    margin-bottom: 0;
    padding: 3em 0;
    background-color: ${color.black};
    &:after {
      content: "";
      display: block;
      width: calc(100% - 80px);
      height: 1px;
      background-color: ${color.halfTransparent};
      margin: 3em auto 0;
      max-width: 1360px;
    }

    h2 {
      text-align: center;
      color: ${color.text.white}
    }
    .form_group {
      input, textarea {
        background-color: transparent;
        color: ${color.text.white};
        border-color: ${color.white};
        &:focus:required:invalid {
          border-color: red;
          box-shadow: none;
          outline: none;
        }
        &:focus:required:valid {
          border-color: ${color.primary};
          box-shadow: none;
          outline: none;
        }
        &:invalid {
          box-shadow: none;
          outline: none;
        }
        &:focus {
          border-color: ${color.primary};
          box-shadow: none;
          outline: none;
        }
        &::placeholder {
          opacity: .8;
          color: ${color.white};
        }
      }
    }
    .submit {
      button {
        color: ${color.text.black};
        background-color: ${color.white};
        border-color: ${color.white};
        &:hover {
          color: ${color.text.white};
          background-color: ${color.primary};
          border-color: ${color.primary};
        }
      }

      &.loading {
        button {
          &:hover {
            color: ${color.text.black};
            background-color: ${color.white};
            border-color: ${color.white};
          }
        }
        .inner_button {
          margin: 0 auto;
          width: 18px;
          height: 18px;
          border-radius: 25px;
          background: #ffffff;
          background: linear-gradient(to right, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
          position: relative;
          animation: load 1.4s infinite linear;
          transform: translateZ(0);
          &:before {
            width: 50%;
            height: 50%;
            background: ${color.black};
            border-radius: 100% 0 0 0;
            position: absolute;
            top: 0;
            left: 0;
            content: '';
          }
          &:after {
            background: ${color.white};
            width: 75%;
            height: 75%;
            border-radius: 50%;
            content: '';
            margin: auto;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
          }
          @-webkit-keyframes load {
            0% {
              -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
            }
            100% {
              -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
            }
          }
          @keyframes load {
            0% {
              -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
            }
            100% {
              -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: ${breakpoint.xsmall}){
    .cta {
      h1 {
        font-size: 4em;
      }
    }
    .form {
      form {
        .form_group {
          &[data-size='50%'] {
            width: 100%;
          }
        }
      }
    }
  }
`;

const GravityTray = () => {
  const [loading, setLoading] = useState(false);
  const [formResponse, setFormResponse] = useState('');
  const [formStatus, setFormStatus] = useState(null);
  const [form, setForm] = useState({
    name: '',
    company: '',
    email: '',
    message: '',
    botfield: ''
  });

  const handleSubmit = (e) => {
    setFormStatus(null)
    setFormResponse('');
    setLoading(true);

    console.log(encode({ 'form-name': 'gravityTray', ...form }) )

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': 'gravityTray', ...form })
    }).then((res) => {
        setFormResponse('Message was sent successfully!');
        setFormStatus('success');
    }).catch((error) => {
        setFormResponse('There was an error trying to send your message. Please try again later.');
        setFormStatus('error');
    }).finally(() => {
      setLoading(false);
    });

    e.preventDefault();
  }

  const encode = (data) => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&')
  }

  const handleChange = (e) => {
    const currentState = form;
    const { name, value } = e.target;
    currentState[name] = value;

    setForm(currentState);
  }

  const renderResponse = () => {
    return (
      <div className={`response ${formStatus}`}>
        <div className='status'></div>
        <div className='message'>{formResponse}</div>
      </div>
    )
  }

  const renderForm = () => {
    return (
      <form name='gravityTray'
        data-netlify='true'
        data-netlify-honeypot='botfield'
        onSubmit={(e) => handleSubmit(e)}>

        <div hidden>
          <label htmlFor='botfield'>Don’t fill this out if you're human:</label>
          <input name='botfield' onChange={(e) => handleChange(e)} />
        </div>
        <div className='form_group' data-size='50%'>
          <label htmlFor='name'>Name:</label>
          <input 
            type='text'
            name='name'
            required={true}
            placeholder='Name'
            onChange={(e) => handleChange(e)}
          />
        </div>
        <div className='form_group' data-size='50%'>
          <label htmlFor='company'>Company:</label>
          <input 
            type='text'
            name='company'
            required={true}
            placeholder='Company'
            onChange={(e) => handleChange(e)}
          />
        </div>
        <div className='form_group' data-size='100%'>
          <label htmlFor='email'>Email:</label>
          <input 
            type='email'
            name='email'
            required={true}
            placeholder='Email'
            onChange={(e) => handleChange(e)}
          />
        </div>
        <div className='form_group' data-size='100%'>
          <label htmlFor='message'>Message:</label>
          <textarea 
            type='text'
            name='message'
            placeholder='Message'
            onChange={(e) => handleChange(e)}
          ></textarea>
        </div>
        <div className={`submit ${loading ? 'loading' : ''}`}>
          <buttons.submit type='submit'>
            <div className='inner_button'>{!loading && 'Submit'}</div>
          </buttons.submit>
        </div>
      </form>
    )
  }

  return (
    <Layout hideMessage={true}>
      <SEO title="Our new GravityTray" />
      <Component>
        <div className="banner-video">
          <video 
            controls
            preload='true'
            poster={poster} 
          >
            <source src={video} type="video/mp4"></source>
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="cta">
          <h2>Introducing</h2>
          <h1>Gravity<i>Tray</i><span>™</span></h1>
        </div>
        <div className="image-row">
          <img src={top} alt="GravityTray Top View"/>
          <img src={bottom} alt="GravityTray Bottom View"/>
        </div>
        <div className="cta cta-full">
          <h3>Gravity<i>Tray</i>™ Technology</h3>
          <p>The protein purge in the top chamber flows to the center of the recyclable container and discharges through the outlet to the bottom chamber. The protein purge in the bottom chamber will not return to the top chamber, due to the funnel-shaped wall between the chambers.</p>
        </div>
        <div className="image-column">
          <img src={side} alt="GravityTray Side View"/>
          <img src={angle} alt="GravityTray Angled View"/>
        </div>
        <div className="cta cta-full cta-list">
          <div className="list">
            <ul>
              <li>Worldwide Patent</li>
              <li>Eliminates Absorbent Pads and Foam Trays</li>
            </ul>
            <ul>
              <li>100% Recyclable PET Container</li>
              <li>Protein Purge Trapping Thermoform</li>
            </ul>
          </div>
        </div>
        <div className='form'>
          <h2>Contact our Sales Team</h2>
          { formStatus && renderResponse() }
          { !formStatus && renderForm() }
        </div>
      </Component>
    </Layout>
  ) 
}

export default GravityTray
